import react from 'react';
import { IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function ExpandMoreToggle({ isOpen}) {
    return (
        <IconButton style={{ transition: 'transform 0.3s', transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}>
            <ExpandMoreIcon />
        </IconButton>
    )
}
