import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { DataGridPro, nlNL } from '@mui/x-data-grid-pro';
import { useNavigate, useParams } from "react-router-dom";
import { parseISODate } from '../util/index'
import Button from './ui/Button';
import { useTranslation } from 'react-i18next';

export default function ProductionDossierList() {
    const navigate =  useNavigate();
    const [list, setList] = useState([]);
    const [title, setTitle] = useState(null);
    const [loading, setLoading] = useState(false);
    const [filterModel, setFilterModel] = useState({items: []})
    const { t } = useTranslation();
   // let routerParams = useParams();

    let routerParams = useParams();
    let orderId = routerParams?.orderId;

    const dataGridColumns = [
      ...(routerParams?.salesNumber ? [] : [{
        field: 'OrdNr',
        headerName: 'Verkoop order',
        width: 250,
        renderCell: (params) => <Link to={`/manco-registratie/${params.row.OrdNr}`} state={{ title: params.row.Description}}>
          {params.row.OrdNr}
        </Link>
        }]
      ),
      ...(routerParams?.salesNumber ? [{
        field: 'ProdHeaderDossierCode',
        headerName: 'Dossiernummer',
        width: 120
      }] : []),
      ...(routerParams?.salesNumber ? [{
        field: 'ProdHeaderOrdNr',
        headerName: 'Maakdossier nr.',
        width: 120
      }] : []),
      {
        field: 'Description',
        headerName: 'Omschrijving',
        width: 300,
      },
      ...(routerParams?.salesNumber ? [{
          field: 'CustPartCode',
          headerName: 'Artikel klant',
          width: 200,
        }] : []),
      ...(routerParams?.salesNumber ? [{
        field: 'StartDate.date',
        headerName: 'Start',
        width: 120,
        valueGetter: (params) => {
          const nestedValue = params.row?.StartDate?.date;
          if (nestedValue) {
            return parseISODate(nestedValue, true);
          } else {
            return "";
          }
        },
      }] : []),
      ...(routerParams?.salesNumber ? [{
        field: 'EndDate.date',
        headerName: 'Eind',
        width: 120,
        valueGetter: (params) => {
          const nestedValue = params.row?.EndDate?.date;
          if (nestedValue) {
            return parseISODate(nestedValue, true);
          } else {
            return "";
          }
        },
      }] : []),
      ...(routerParams?.salesNumber ? [{
        field: 'Status',
        headerName: 'Status',
        width: 200,
        valueGetter: (params) => params.row?.ProdStatusCode + " " + params.row?.ProdStatusDescription
      }] : []),
      ...(routerParams?.salesNumber ? [{
        field: 'articleButton',
        headerName: '',
        width: 150,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderHeaderFilter: () => null,
        renderCell: params => <Link to={`/manco-registratie/${params.row.OrdNr}/bom/${params.row.ProdHeaderDossierCode}/${params.row.ProdHeaderOrdNr}`}><Button variant="outline">{t('Manco')}</Button></Link>
      }] : []),
      ...(routerParams?.salesNumber ? [{
        field: 'operationButton',
        headerName: '',
        width: 160,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderHeaderFilter: () => null ,
        renderCell: params => <Link to={`/manco-registratie/${params.row.OrdNr}/operations/${params.row.ProdHeaderDossierCode}/${params.row.ProdHeaderOrdNr}`}><Button variant="outline">{t('Mankement')}</Button></Link>
      }] : []),
    ];
    // ProdStatusCode
    // :
    // "30"
    // ProdStatusDescription
    // :
    // "Voorbereidingsfase"
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + "/isah/productiondossier" + (routerParams?.salesNumber ? "/" + routerParams.salesNumber : ""));
                setLoading(false)
                if (routerParams?.salesNumber) {
                    let titleItem = response.data.find(item => item.ProdStatusCode === "51")
                    setTitle(titleItem?.Description);
                    setList(response.data.filter(item => item.ProdStatusCode !== "51"));
                } else {
                    setList(response.data);
                }
            } catch (error) {
                console.error("Error:", error);
            }
        };

        fetchData();
        return () => {
            setList([]);
            setTitle(null);
            setFilterModel({ items: [] });
        }
    }, [routerParams]);

    return (
        <div>
            {title ? <Button variant="secondary" icon={false} onClick={() => navigate('/manco-registratie')}>{t("terug naar overzicht")}</Button> : null}
            <h1>Manco Registratie {routerParams?.salesNumber ? " | " + routerParams?.salesNumber : ""}</h1>
            {title ? (<h4>{title}</h4>): null}

            <div className="list">
            <DataGridPro
                getRowId={(row) => row.ProdHeaderDossierCode}
                rows={list}
                unstable_headerFilters
                disableColumnFilter
                columns={dataGridColumns}
                localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
                initialState={{
                    pagination: {
                    paginationModel: {
                        pageSize: 30,
                    },
                    },
                }}
                pageSizeOptions={[30, 60, 90]}
                filterModel={filterModel}
                onFilterModelChange={(model) => setFilterModel(model)}
                />
            </div>
        </div>
    )
}
