import React from 'react';
import { useLocation, useNavigate} from 'react-router-dom';
import PafBasicInformation from './BasicInformation';
import Button from '../ui/Button';
import { useTranslation } from 'react-i18next';
import { formatNumber} from '../../util/index';
import DividerLine from '../ui/DividerLine';
import {constructPurchReqTextLines} from './PdfStore/TextTemplatePdf';
import { useMsal } from '@azure/msal-react';
import {constNrOfLinesInOnePdfPage} from '../constants/constants';

import axios from 'axios';
import { useState } from 'react';

import jsPDF from 'jspdf';

export default function Overview() {
    const location = useLocation();
    const { t } = useTranslation();
    const navigate =  useNavigate();

    const [purchReqStructure, setPurchReqStructure] = useState(location.state.purchReqStructure);
    const [emailMessage, setEmailMessage] = useState('');
    const { accounts } = useMsal();
    const account = accounts[0];

    const [approvalFileObject, setApprovalFileObject] = useState({...location.state.purchReqStructure.approvalFileObject});
    const [quoteFilesAreSelected, setQuoteFilesAreSelected] = useState(false);
    const [quoteFileObjects, setQuoteFileObjects] = useState(location.state.purchReqStructure.quoteFileObjects);

    const handleApprovalFile = (event) => {
        setEmailMessage('');
        let newPurchReqStructure = {...purchReqStructure};
        if (event.target.files.length > 0) {
            const file = event.target.files[0];

            const fileObject = {
                name: file.name,
                size: file.size,
            };
            setApprovalFileObject(fileObject);
            newPurchReqStructure.approvalFile = file;
            newPurchReqStructure.approvalFileObject = fileObject;
            setPurchReqStructure(newPurchReqStructure);
        }
    }

    const handleQuoteFiles = (event) => {
        setEmailMessage(false);
        let newPurchReqStructure = {...purchReqStructure};
        // newPurchReqStructure.quoteFiles = [];
        // newPurchReqStructure.quoteFileObjects = [];
        if (event.target.files.length > 0) {
            const files = [...event.target.files];
            let fileObjects = [...newPurchReqStructure.quoteFileObjects];
            for (let i = 0; i < files.length; i++) {
                fileObjects.push({
                    // id: i + 1,
                    id: newPurchReqStructure.quoteFileObjects.length + i + 1,
                    name: files[i].name,
                    size: files[i].size
                });
            }
            setQuoteFilesAreSelected(true);
            setQuoteFileObjects(fileObjects);
            newPurchReqStructure.quoteFiles = newPurchReqStructure.quoteFiles.concat(files);
            newPurchReqStructure.quoteFileObjects = fileObjects;

            setPurchReqStructure(newPurchReqStructure);
        }
    }

    const sendEmail = () => {
        let files = [...purchReqStructure.quoteFiles];
        files.push(purchReqStructure.approvalFile);

        const promises = Array.from(files).map(file => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = function(event) {
                    resolve({
                        fileName: file.name,
                        content: event.target.result
                    });
                };

                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        });

        Promise.all(promises)
        .then(files => {
            const purchReqTextLines = constructPurchReqTextLines(account.name, purchReqStructure);
            const purchReqTextLinesHtml = purchReqTextLines.replaceAll('\n', '<br />');

            const attachmentList = [];

            for (let i = 0; i < files.length; i++) {
                attachmentList.push({
                    fileName: files[i].fileName,
                    fileData: files[i].content
                });
            }

            let postBody = {
                email : process.env.REACT_APP_PURCHASE_EMAIL,
                name : "Inkoop afdeling",
                subject : "Inkoopaanvraag",
                cc: account.username,
                ccName: account.name,
                emailbody : purchReqTextLinesHtml,
                attachmentList
            }

            axios.post(process.env.REACT_APP_API_URL + "/demeeuw/email/send", postBody)
            .then(response => {
                setEmailMessage('E-mail is verstuurd');
            })
            .catch(error => {
                setEmailMessage('Fout: E-mail is niet verstuurd');
            });
        })
        .catch(error => {
            console.error("Error reading files:", error);
        });
    }

    const deleteAttachments = () => {
        setQuoteFilesAreSelected(false);
        const apprFileObj = ({
            name: '',
            size: ''
        });
        setApprovalFileObject(apprFileObj);
        setQuoteFileObjects([]);
        let newPurchReqStructure = {...purchReqStructure};
        newPurchReqStructure.approvalFile = null;
        newPurchReqStructure.approvalFileObject = apprFileObj;
        newPurchReqStructure.quoteFiles = [];
        newPurchReqStructure.quoteFileObjects = [];
        setPurchReqStructure(newPurchReqStructure);
    }

   // Do not code printPdf() in another source file: download will go wrong.
    const printPdf = () => {
        const pdfDocText = new jsPDF(); // text variant
        pdfDocText.setFontSize(10); // werkt niet

        const totalText = constructPurchReqTextLines(account.name, purchReqStructure);

        purchReqStructure.totalText = totalText;
        setPurchReqStructure(purchReqStructure);

        const totalTextArray = totalText.split('\n');
        const numberOfLines = totalTextArray.length;
        const nrOfPdfPages = Math.ceil(numberOfLines / constNrOfLinesInOnePdfPage);

        pdfDocText.setFontSize(10);

        for (let p = 0; p < nrOfPdfPages; p++) {
            let startIdx = p * constNrOfLinesInOnePdfPage;
            let endIdx = (p + 1) * constNrOfLinesInOnePdfPage;
            let textOnPage = '';
            let endLine = 0;
            for (let line = startIdx; line < endIdx && line < totalTextArray.length; line ++) {
                textOnPage += '\n' + totalTextArray[line];
                endLine = line;
            }
            pdfDocText.text(textOnPage, 10, 10);
            if (endLine < (totalTextArray.length - 1)) {
                pdfDocText.addPage();
            }
        }
        const date = new Date().toISOString();
        pdfDocText.save('Inkoop aanvraag ' + date + ' ' + purchReqStructure.vendor.name + '.pdf'); // variant die met text werkt
    }

    const gotoArticlesScreen = () => {
        navigate(`/inkoop-aanvraag/artikelen/${purchReqStructure.vendor.vendid}`, {state: {
            purchReqStructure: {...purchReqStructure}
        }});
    }

    return (
        <>
            <h2>Inkoop aanvraag - totaaloverzicht en verwerken</h2>
            <div className="row">
                <div>
                    <PafBasicInformation purchReqStructure={purchReqStructure}/>
                </div>
                <div>
                    <p>
                        <strong>
                            {`Totaalprijs ${purchReqStructure.requestType === 'cost' ? "GBK" : "SP"}-artikelen: ${formatNumber(purchReqStructure.totalPriceManualArticles, 2, 2)}`}
                        </strong>
                    </p>
                    <p>
                        <strong>
                            Totaalprijs ISAH-artikelen: {formatNumber(purchReqStructure.totalPriceIsahArticles, 2, 2)}
                        </strong>
                    </p>
                    <DividerLine />
                    <p><strong>Totaalprijs alle artikelen: {formatNumber(purchReqStructure.totalGeneralPrice, 2, 2)}</strong></p>
                </div>
            </div>

            <DividerLine />
            <form className="Form">
                <div>
                    <div>
                        <p><strong>Goedkeuring Budgethouder/Projectleider volgens Procuratiematrix (*)</strong></p>
                        <label className='input-file-button' htmlFor="approvalFile">Kies pdf file</label>
                        {/* Note: the onClick() is needed if the end user wants to select the same file again  */}
                        <input
                            id = "approvalFile"
                            accept=".pdf"
                            type="file"
                            style={{visibility: 'hidden'}}
                            onChange = {handleApprovalFile}
                            onClick={(event)=> {
                                event.target.value = null
                            }}
                        />
                    </div>
                    <div>
                        { approvalFileObject.name !== '' ?
                            <h4 style={{color: 'red'}}>{approvalFileObject.name}</h4>
                        :
                            null
                        }
                    </div>
                </div>

                <div>
                    <div>
                        <p><strong>Kies offertes, specificaties en tekeningen</strong></p>
                        <label className='input-file-button' htmlFor="quoteFiles">Voeg pdf files toe</label>
                        {/* Note: the onClick() is needed if the end user wants to select the same files again  */}
                        <input
                            id = "quoteFiles"
                            accept=".pdf"
                            type="file"
                            style={{visibility: 'hidden'}}
                            multiple
                            onChange = {handleQuoteFiles}
                            onClick={(event)=> {
                                event.target.value = null
                            }}
                        />
                    </div>
                    <div>
                        {
                            quoteFileObjects.map((qtObj) =>
                                <div key={qtObj.id}>
                                    <h4 >{qtObj.name}</h4>
                                </div>
                            )
                        }
                    </div>
                </div>
            </form>

            <div className="row">
                { (approvalFileObject.name != '' || quoteFilesAreSelected) ?
                    <Button variant="primary" icon={false} onClick={deleteAttachments} >
                        {t("Verwijder attachments")}
                    </Button>
                :
                    null
                }

                <Button variant="primary" onClick={printPdf}>
                    Voorbeeld e-mail tekst
                </Button>
                <Button
                    variant={approvalFileObject.name !== '' ? 'primary' : 'disabled'}
                    onClick={approvalFileObject.name !== '' ? sendEmail: null}
                >
                    Stuur email
                </Button>
            </div>
            <span>{emailMessage}</span>
            <div className="row">
                <Button variant="secondary" icon={false} onClick={gotoArticlesScreen}>
                        {t("Terug naar artikelen")}
                </Button>
            </div>

        </>
    )
}
