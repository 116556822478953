import React, { useRef, useEffect } from "react";

const TerrainList = () => {
  const canvasRef = useRef(null);
  const rectSize = 50;
  const rectSpacing = 10;
  let data = [
    [0, 1, 2, 3],
    [3, 2, 1, 0],
    [0, 1, 2, 3],
    [3, 2, 1, 0]
  ];

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    canvas.width = data[0].length * (rectSize + rectSpacing);
    canvas.height = data.length * (rectSize + rectSpacing);

    for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
            let intensity = data[i][j] / 3.0;
            let color = Math.floor(intensity * 255);
            context.fillStyle = 'rgb(' + color + ', ' + color + ', ' + color + ')';
            context.fillRect(j * (rectSize + rectSpacing), i * (rectSize + rectSpacing), rectSize, rectSize);
        }
    }
  }, [data]);

  return (
    <div>
      <h1>Terrain</h1>
      <canvas ref={canvasRef} />
    </div>

  );
}

export default TerrainList;
