import React, { useEffect, useState } from 'react';
import { DataGridPro, nlNL } from '@mui/x-data-grid-pro';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { formatAmountWith2Decimals } from '../../util/formatHelper';
import axios from 'axios';

export default function Vendors()   {
    const navigate =  useNavigate();

    const handleRowDoubleClick = (params, event) => {
        navigate(`/vendors/artikelen/${params.row.id}`, {state: {
            vendorName: params.row.name
        }});
      };

    const location = useLocation();

    const [vendorList, setVendorList] = useState([]);
    const [articleList, setArticleList] = useState([]);
    const [loading, setLoading] = useState(true);

    const routerParams = useParams();

    const dataGridColumnsVendor = [
        {
            field: 'id',
            headerName: 'Number',
            width: 150,
        },
        {
            field: 'name',
            headerName: 'Naam',
            width: 300
        },
        {
            field: 'addr',
            headerName: 'Adres',
            width: 300,
        },
        {
            field: 'phone',
            headerName: 'Telefoon',
            width: 250,
        }
    ]

    const dataGridColumnsArticles = [
        {
            field: 'id',
            headerName: 'Artikelnummer',
            width: 150,
        },
        {
            field: 'description',
            headerName: 'Omschrijving',
            width: 300
        },
        {
            field: 'vendPartCode',
            headerName: 'Artikelnummer leverancier',
            width: 250
        },
        {
            field: 'purUnit',
            headerName: 'Inkoop-eenheid',
            width: 150
        },
        {
            field: 'ordQty',
            headerName: 'Bestelhoeveelheid',
            width: 200
        },
        {
            field: 'purchasePriceDescription',
            headerName: 'Inkoopprijs',
            width: 200
        },
    ]

    useEffect(() => {
        const fetchData = async () => {
            if (!routerParams.vendorId) {
                try {
                    const responseVendors = await axios.get(process.env.REACT_APP_API_URL + '/isah/vendor/getList');
                    setLoading(false);
                    const vendorListData = responseVendors.data.filter(vend => vend.name !== 'DEFAULT');
                    const vendors = vendorListData.map (elem => {
                        return {
                            id: elem.vendid,
                            name: elem.name,
                            addr: elem.addr + ', ' + elem.postcode + ' ' + elem.city,
                            phone: elem.phone
                        }
                    });
                    setVendorList(vendors);
                }
                catch (error) {
                    console.error('Error fetching vendors:', error);
                }
            } else {
                try {
                    const responseArticles = await axios.get(process.env.REACT_APP_API_URL + '/isah/article/getListByVendorId/' + routerParams.vendorId);
                    setLoading(false);
                    responseArticles.data.sort( (a, b) => a.Description.localeCompare(b.Description));
                    const articles = responseArticles.data.map (elem => {
                        return {
                            id: elem.PartCode,
                            description: elem.Description,
                            vendPartCode: elem.VendPartCode,
                            purUnit: elem.PurUnit,
                            ordQty: parseFloat(elem.OrdQty),
                            purchasePriceDescription: `€ ${formatAmountWith2Decimals(elem.CurrPurPrice)} voor ${elem.PurComputQty} ${elem.PurUnit}`
                        }
                    });
                    setArticleList(articles);
                }
                catch (error) {
                    console.error('Error fetching articles from vendor:', error);
                }
            }

        };

        fetchData();
    }, [routerParams]);

    return (
        <>

            {
                !routerParams.vendorId ?
                    <h2>Lijst van leveranciers</h2>
                :
                    <h2>Artikelen voor leverancier {location.state.vendorName}</h2>
            }
            {
                loading ?
                    <div className="loader"></div>
                :
                    !routerParams.vendorId
                ?
                    <div className="list">
                        <DataGridPro
                            getRowId={(row) => row.id}
                            rows={vendorList}
                            unstable_headerFilters
                            columns={dataGridColumnsVendor}
                            localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
                            pageSizeOptions={[30, 60, 90]}
                            onRowDoubleClick={handleRowDoubleClick}
                        />
                    </div>
                :

                    <div className="list">
                        <DataGridPro
                            getRowId={(row) => row.id}
                            rows={articleList}
                            unstable_headerFilters
                            columns={dataGridColumnsArticles}
                            localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
                            pageSizeOptions={[30, 60, 90]}
                        />
                    </div>
            }

        </>
    );
}
