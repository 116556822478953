import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { formatPrice } from '../util';

export default function Quote() {
    const { quoteId } = useParams();
    const [quote, setQuote] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
              // pagination take: 22 and skip: 22
              const quoteResponse = await axios.get(process.env.REACT_APP_API_URL + "/kbmax/quote/" + quoteId);
              setQuote(quoteResponse.data);
            } catch (error) {
              console.error("Error:", error);
            }
          };

          fetchData();
    }, []);

    return (
        <div>
        {quote ?
            <div>
                <h1>{quote.name}</h1>
                quote id: {quoteId}<br/>
                price: {formatPrice(quote.totalPrice)}
                <div>
                    {
                        quote.products.map((product, key) => {
                            return (
                                <div key={key}>
                                    <h2>Gebouw</h2>
                                    <Link to={`/gebouw/${product.id}`}>
                                    {product.name}</Link><br/>
                                    {product.id}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        :
            "..."
        }
        </div>
    )
}