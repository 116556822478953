import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import NotFound from "./NotFound";

export default function AuthenticatePage(props) {
    const { t } = useTranslation();
    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();
    const account = accounts[0] || false;

    const { item } = props;

    const checkRole = (roles) =>!roles || roles.length === 0 || hasRole(account, roles);
    const hasRole = (account, roles) => roles.some(role => account?.idTokenClaims?.roles?.includes(role));

    if (isAuthenticated && item?.path === "*") {
        return <div className="main-content"><NotFound/></div>
    }

    if (isAuthenticated && !checkRole(item?.roles)) {
        return <div className="main-content">
            <h5>
                <center>
                    {t("U bent niet geauthoriseerd om deze pagina te zien.")}
                </center>
            </h5>
        </div>
    }

    if (isAuthenticated && checkRole(item?.roles)) {
        return <div className="main-content"><item.component /></div>;
    }

    return (
        <div className="main-content">
            <h5>
                <center>
                    {t("U dient in te loggen om deze pagina te zien.")}
                </center>
            </h5>
        </div>
    );
}
