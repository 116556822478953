import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Button from '../../ui/Button';
import TrashIcon from '../../icons/TrashIcon'
import EditPencilIcon from '../../icons/EditPencilIcon'

export default function ArticlesPerVendor({articleList, requestType, onDelete, onUpdate})   {
    const handleDeleteButton = (e, row) => {
        onDelete(row.id);
    };

    const handleUpdateButton = (e, row) => {
        onUpdate(row.id);
    };

    const dataGridColumns = [
        {
            field: 'id',
            hide: true,
            width: 50
        },
        {
            field: 'articleNumberDescription',
            headerName: 'Artikel nummer',
            width: 150,
        },
        {
            field: 'description',
            headerName: 'Omschrijving',
            width: 250,
        },
        {
            field: requestType === 'cost' ? 'gbkNumber' : 'spNumber',
            headerName: requestType === 'cost' ? 'GBK-nr' : 'SP-nr',
            width: 100,
        },
        {
            field: requestType === 'cost' ? 'gbkNumberDescription' : 'spNumberDescription',
            headerName: requestType === 'cost' ? 'GBK-omschrijving' : 'SP-omschrijving',
            width: 200,
        },
        {
            field: 'numberDescription',
            headerName: 'Aantal',
            width: 125,
        },
        {
            field: 'priceDescription',
            headerName: 'Stukprijs ex BTW',
            width: 150,
        },
        {
            field: 'totalPriceDescription',
            headerName: 'Totaal bedrag',
            width: 125,
        },
        {
            field: 'deliveryDateDescription',
            headerName: 'Afleverdatum',
            width: 125,
        },
        {
            field: 'actions',
            headerName: 'Akties',
            width: 300,
            renderCell:  (params) => {
                return (
                <div>
                    <Button
                        inline={true}
                        onClick={(e) => handleUpdateButton(e, params.row)}
                        icon={false}
                    >
                        <EditPencilIcon/>
                    </Button>
                    <Button
                        inline={true}
                        onClick={(e) => handleDeleteButton(e, params.row)}
                        icon={false}
                    >
                        <TrashIcon/>
                    </Button>
                </div>
                );
            }
        },
    ];

    return (
        <>
            <h4>Lijst van geselecteerde artikelen</h4>

            <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                rows={articleList}
                columns={dataGridColumns}
                initialState={{
                    pagination: {
                    paginationModel: {
                        pageSize: 30,
                    },
                    },
                }}
                pageSizeOptions={[30, 60, 90]}
                />
            </Box>
        </>
    );
}
