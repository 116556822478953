import React, { useEffect, useState } from 'react';
import { TextField } from "@mui/material";
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';

export default function SelectCostCenter({costCenter, onSelectCostCenter})   {
    const [costCenterList, setCostCenterList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + '/isah/costCenter/getListForPaf/');
                const data = response.data.map(costcenter => {
                    return {
                        costCenterCode: costcenter.CostCenterCode.trim(),
                        fullDescription: costcenter.CostCenterCode.trim() + ' (' + costcenter.Description + ')'
                    }
                });
                setCostCenterList(data);
            }
            catch (error) {
                console.error('Error fetching posts:', error);
            }
        };

        fetchData();
    }, []);

    const handleCostCenter = (event, option) => {
        onSelectCostCenter(option);
    }

    return (
        <>
            <p><strong>Kostenplaats (*)</strong></p>
            <Autocomplete
                disablePortal
                disableClearable
                id="cost-center-auto-complete"
                options={costCenterList}
                getOptionLabel={(option) =>
                    (option.fullDescription)
                }
                sx={{ width: 300 }}
                onChange={handleCostCenter}
                value={costCenter || null}
                renderInput={(params) => <TextField {...params} label="Kostenplaats" />}
            />
        </>
    );
}
