import React, { useEffect, useState } from "react";
import { fetchData } from '../util/fetchData';
import { DataGridPro } from '@mui/x-data-grid-pro/DataGridPro';

const TransportPlanning = () => {
    const [transportList, setTransportList] = useState([]);
    // Loading time and travel time in minutes
    const [loadingTime, setLoadingTime] = useState(0);
    const [travelTime, setTravelTime] = useState(0);

    const columns = [
        {
            field: 'LineNr',
            headerName: 'Ritnr.',
            type: 'string',
            width: 90,
          },
          {
            field: 'ServObjectCode',
            headerName: 'Type',
            type: 'string',
            width: 90,
          },
          {
            field: 'ServObjectode',
            headerName: 'Unitnr.', // Triton
            type: 'number',
            width: 90,
          },
          {
            field: 'tag',
            headerName: 'Tag J/N',
            type: 'number',
            width: 90,
          },
          {
            field: 'location',   // OriginType
            headerName: 'Location',
            type: 'number',
            width: 90,
          },
          {
            field: '', // WareHouseCode
            headerName: 'Vak',
            type: 'number',
            width: 90,
          },
          {
            field: 'd',
            headerName: 'Bijladen',
            type: 'number',
            width: 90,
          },
          {
            field: 'age',
            headerName: 'Voorladen',
            type: 'number',
            width: 90,
          },
          {
            field: 'c',
            headerName: 'Datum',
            type: 'number',
            width: 90,
          },
          {
            field: 'a',
            headerName: 'Tijd',
            type: 'number',
            width: 90,
          },
          {
            field: 'aa',
            headerName: 'Datum',
            type: 'number',
            width: 90,
          },
          {
            field: 'bb',
            headerName: 'Tijd',
            type: 'number',
            width: 90,
          },

    ]
    useEffect(() => {
        async function getTransportPlanning() {
          // V23-07828
          // V23-07828
            let dossierCode = '172661';
            let data = await fetchData("/isah/transport/" + dossierCode);
            setTransportList(data)
        }
        getTransportPlanning();
    }, []);
    // vnummer
    // project
    // adres

    // WareHouseCode "Hal 36"
    // WHDescr Centrale magazijn

    return (
        <div>
            <h1>Transport Planning</h1>
{/* ritnr, pl volg, type=servobjectgrp, triton=servobjectcode, bijladen, locatie c15, voorladen, datum laden, tijd laden, datum lossen, tijd lossen, naam, ?? */}
            {transportList.length > 0 ?
                <div className="transport-planning-head">
                    <div>
                        <span>Laden</span>{transportList[0].OrdNr}<br />
                        <span>Project</span>{transportList[0].ToCustName}<br />
                        <span>Adres</span>{transportList[0].ToCustAddr} {transportList[0].ToCustCity}<br />
                        <span>Transportvolgorde</span><br />
                        <span>Datum</span><br />
                    </div>
                    <div>Laadtijd<br />
                    0:00
                    </div>
                    <div>Rijtijd <br/>1:00</div>
                </div>
            :
                null
            }

            {transportList && transportList.length > 0 ?
                <DataGridPro
                    getRowId={(row) => row.LineNr}
                    rows={transportList}
                    pageSize={transportList.length}
                    columns={columns}
                />
            :
                null
                // <p>{item.LineNr} {item.ServObjectGrp.trim()} {item.ServObjectCode.trim()}</p>
            }
        </div>
    );
}

export default TransportPlanning;
