import React, { useEffect } from 'react';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import gantt from 'dhtmlx-gantt';
import { convertDateFormat, getDuration } from '../util/dateHelper';

const GanttChart = ({data}) => {

  useEffect(() => {
    const newList = data.map((item, key) => {
        let duration = getDuration(item['Werkelijke ProductionHeader Startdatum'], item['Werkelijke ProductionHeader Einddatum'])
        const startDate = convertDateFormat(item['Werkelijke ProductionHeader Startdatum']);
        return {
            id: key + 1,
            text: item.Order + ' ' + item.Name + ' ' + (item.ProdHeaderDossierCode || ""),
            start_date: startDate,
            duration: duration
        }
    })

    if (newList.length > 0) {
      gantt.init("gantt_here");
      gantt.config.columns = [
        {name:"text", label:"Project", width:"310", align: "left", tree: false },
        {name:"start_date", label:"Start", width: "100", align: "left" },
        // {name:"duration", label:"Duration", align: "center" }
      ];
      gantt.config.scales = [
            {unit: "year", step: 1, format: "%Y"},
            // {unit: "month", step: 3, format: monthScaleTemplate},
            {unit: "month", step: 1, format: "%M"}
        ];
        gantt.config.scale_height = 54;

        gantt.parse({
          data: newList
        });
    }

  }, [data]);

  return (
    <div id="gantt_here" style={{ width: '100%', height: '700px' }}></div>
  );
};

export default GanttChart;
